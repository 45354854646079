<template>
  <div class="cart-container">
    <div class="cart-container-header">
      <h4 class="checkout-title" v-text="'4. סיכום הזמנה'" />
    </div>

    <div class="cart-total">
      <div class="cart-total-body">
        <div class="cart-checkout intermediate-summary">
          <p><span class="shekel" v-text="'₪'" /> {{ cart.totalProds }}</p>
          <p>סיכום ביניים ( {{allQuantity}} מוצרים)</p>
        </div>

       <div v-if="cart.voucher" class="cart-checkout">
         <p><span class="shekel">₪</span> {{ (cart.totalVoucher ? - cart.totalVoucher : 0) }} </p>
         <p>
           קופון
           <a href="#" @click.prevent="showCoupon = !showCoupon" v-if="cart.totalVoucher == 0">
             קופון הנחה? הזיני אותו כאן
           </a>
           <a  href="#" v-else @click.prevent="cancelCoupon">
            בטל קופון  (מקנה: {{ cart.voucher.amount}}{{ cart.voucher.type_discount == 'NIS' ? '₪' : "%" }} הנחה)
           </a>
         </p>
       </div>


       <div class="coupon-form" v-if="showCoupon && cart.totalVoucher == 0">
         <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-primary" @click.prevent="checkCoupon" type="button" id="button-addon1">
                ניצול קופון
              </button>
            </div>
           <input type="text" class="form-control" v-model="codeCoupon" placeholder="קופון הנחה? הזיני אותו כאן" aria-label="Example text with button addon" aria-describedby="button-addon1">
         </div>
         <div  style="font-size:80%;color:#dc3545;margin-bottom:1rem;direction:rtl;">{{ voucherErrMsg }}</div>
       </div>

        <div v-if="delivery" class="cart-checkout intermediate-summary">
          <p><span class="shekel" v-text="'₪'" /> {{ delivery.price }}</p>
          <p>שליח עד הבית</p>
        </div>

        <div class="cart-checkout total-payment">
          <p><span class="shekel" v-text="'₪'" /> {{ cart.total }}</p>
          <p><b>סה"כ לתשלום</b></p>
        </div>
      </div>

      <div class="cart-total-footer">
        <button type="submit" class="btn btn-primary btn-block" @click.prevent="payment">
          <div class="spinner-border text-light" role="status" v-if="loading">
            <span class="sr-only" v-text="'Loading...'" />
          </div>
          מעבר לתשלום
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import device from 'current-device'
import { eventEmitter } from '@/main'
import cartMixin from '@/mixins/cart.mixin'
import { mapState } from 'vuex'

export default {
  name: 'OrderSummary',

  mixins: [cartMixin],

  data() {
    return {
      device,
      codeCoupon: '',
      loading: false,
      showCoupon: false,
    }
  },

  computed: {
    cart () {
      return this.$store.state.cart.cart
    },
    delivery () {
      return this.$store.state.cart.delivery
    },
    saveCheckoutStatus() {
      return this.$store.getters.saveCheckoutStatus
    },
    allErrors() {
      return this.$store.getters.allErrors
    },
    cartErrArr() {
      return this.$store.getters.cartErrArr
    },
    voucherErrMsg() {
      return this.$store.getters.voucherErrMsg
    },
  },

  watch: {
    saveCheckoutStatus() {
      this.loading = false
    },
    allErrors() {
      this.loading = false
    },
    cartErrArr(newCartErrArr) {
      if(newCartErrArr.length) {
        this.loading = false

        if(device.mobile()) {
          eventEmitter.$emit('openSmallCart')
        }
      }
    }
  },

  methods: {
    payment() {
      this.loading = true
      this.$store.commit('clearErrors')
      eventEmitter.$emit('checkShippingDetails')
    },
    checkCoupon(){
      if(this.codeCoupon){
        this.$store.commit('clearErrors')
        eventEmitter.$emit('checkCoupon', this.codeCoupon)
      }
    },
    cancelCoupon(){
      eventEmitter.$emit('cancelCoupon')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.cart-container{
  background-color: #fafafa;
}
.cart-container-header{
  margin-bottom: .9rem;
  padding-top: .7rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
}
#checkout .cart-container-header h4{
  margin-bottom: 0;
  padding-top: .6rem;
}
#checkout .cart-container-header .btn {
  height: inherit;
  margin-bottom: 0;
  font-family: 'Varela Round', sans-serif;
  font-size: .8rem;
  font-weight: normal;
  padding: .45rem 1.5rem;
}
.cart-total-body{
  padding-right: 1.3rem;
  padding-left: 1.3rem;
}
#checkout .gong-form .checkout-footer .coupon-form .btn{
  font-size: 1rem;
  height: inherit;
  line-height: 1;
  margin-top: 0;
}
#checkout .coupon-form .form-control{
  height: inherit;
  line-height: 1;
}
.coupon-form .input-group{
  margin-bottom: 1rem !important;
}
#checkout .gong-form .checkout-footer .btn {
  line-height: 1.7;
}
.spinner-border{
  width: 1.7rem;
  height: 1.7rem;
  vertical-align: middle;
}

@include media-breakpoint-down(sm) {
  #checkout .gong-form .cart-container-header .btn{
    margin-top: 0;
    font-size: .8rem;
    height: inherit;
    line-height: 1.7;
  }
  #checkout .gong-form .checkout-footer .btn{
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
  #checkout .gong-form .checkout-footer .spinner-border {
    vertical-align: middle;
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .cart-container{
    margin-bottom: 1.5rem;
  }
}
</style>
