<template>
  <div id="paymentMethods">
    <div class="checkout-header">
      <h4 class="checkout-title" v-text="'3. שיטת תשלום'" />
    </div>

    <div class="checkout-body" id="payment">
      <div class="custom-control custom-radio" v-for="(paymentItem, index) of payments" :key="index">
        <input
          type="radio"
          :id="'payment-'+index"
          :class="['custom-control-input', {'is-invalid': errorsPayment.msg}]"
          :value="paymentItem.id"
          v-model="payment"
          :disabled="cart.totalVoucher > 0 && cart.total == 0"
        >
        <label class="custom-control-label" :for="'payment-'+index" v-text="paymentItem.title" />

        <div class="invalid-feedback" v-if="errorsPayment.msg" v-text="errorsPayment.msg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentMethods',

  data() {
    return {
      payments: [],
      payment: null,
    }
  },

  computed: {
    cart () {
      return this.$store.state.cart.cart
    },
    details() {
      return this.$store.getters.details
    },
    form() {
      return this.$store.getters.form
    },
    updateDetailsUserStatus() {
      return this.$store.getters.updateDetailsUserStatus
    },
    saveCheckoutStatus() {
      return this.$store.getters.saveCheckoutStatus
    },
    errorsPayment() {
      return this.$store.getters.errorsPayment
    },
    voucherStatus () {
      return this.$store.getters.voucherStatus
    }
  },

  watch: {
    details(newDetails) {
      this.payments = newDetails.data ? newDetails.data.paymentOpt : []
    },

    errorsPayment(newErr) {
      if(newErr.key) {
        document.querySelector('#'+newErr.key).scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },

    async updateDetailsUserStatus(newVal) {
      if(newVal) {
        const form = this.form
        if(this.payment) {
          form.append('paymentOpt', this.payment)
        }
        if(window.ReactNativeWebView){
          form.append('app' , 1)
        }

        await this.$store.dispatch('saveCheckout', {form})
      }
    },

    saveCheckoutStatus(newStatus) {
      if(newStatus && !this.voucherStatus) {
        this.$router.push({name: 'payments'})
      } else if (newStatus && this.voucherStatus) {
        this.$store.commit('clearCart')
        this.$router.push({ name: 'ThankYou' })
      } else {
        this.$router.push('/')
      }
    }
  },
}
</script>

<style scoped>
.gong-form .custom-control{
  margin-bottom: .5rem;
}
.gong-form .custom-control-input.is-invalid ~ .custom-control-label{
  color: #000;
}
</style>
