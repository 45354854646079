<template>
  <div id="shippingDetails">
    <div class="checkout-container">
      <div class="checkout-header">
        <h4 class="checkout-title" v-text="'2. פרטי משלוח'" />
      </div>

      <div class="checkout-body">
        <div id="delivery">
          <div class="custom-control custom-radio" v-for="(deliveryItem, index) of deliveries" :key="index">
            <input
              type="radio"
              :id="'delivery-'+index"
              :class="['custom-control-input', {'is-invalid': errorsDelivery.msg}]"
              :value="deliveryItem.id"
              v-model="form.delivery"
            >

            <label class="custom-control-label" :for="'delivery-'+index">
              שליח עד הבית - {{ deliveryItem.price }} ₪*
            </label>

            <div class="invalid-feedback" v-if="errorsDelivery.msg" v-text="errorsDelivery.msg" />
          </div>
        </div>

        <p class="checkout-note" v-text="'שימי לב: אנו ממליצים לציין כתובת מקום עבודה ו/או שעות נוחות לקבלת המשלוח בהערות במידה ואת לא נמצאת בביתך במהלך היום.'" />

        <div class="form-row">
          <div class="form-group col">
            <input type="text" id="lname" placeholder="שם משפחה" :class="['form-control', {'is-invalid': errorsFname.msg}]" v-model="form.fname" autofocus>
            <div class="invalid-feedback" v-if="errorsFname.msg" v-text="errorsFname.msg" />
          </div>

          <div class="form-group col">
            <input id="fname" type="text" placeholder="שם" :class="['form-control', {'is-invalid': errorsLname.msg}]" v-model="form.lname">
            <div class="invalid-feedback" v-if="errorsLname.msg" v-text="errorsLname.msg" />
          </div>
        </div>

        <div class="form-group">
          <input type="email" id="email" placeholder="אימייל" :class="['form-control', {'is-invalid': errorsEmail.msg}]" v-model="form.email" :readonly="isLogin">
          <div class="invalid-feedback" v-if="errorsEmail.msg" v-text="errorsEmail.msg" />
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col">
              <input id="phone" type="text" placeholder="טלפון" :class="['form-control', {'is-invalid': errorsPhone.msg}]" v-model="form.phone">
              <div class="row">
                <div class="col-12 invalid-feedbackStyle" v-if="errorsPhone.msg">
                  {{errorsPhone.msg}}
                </div>
              </div>
            </div>
            <div class="col-4 col-md-3 pr-0 areaCodeSelect">
              <select
              v-model="form.areaPhone"
              :class="['form-control style-select', {'is-invalid': errorsAreaPhone.msg}]"
              >
                <option value="">קידומת</option>
                <option :value="areaCode.title" v-for="(areaCode, index) of areaCodes" :key="index">{{areaCode.title}}</option>
              </select>
              <div class="row">
                <div class="col-12 invalid-feedbackStyle" v-if="errorsPhone.msg">
                  {{errorsAreaPhone.msg}}
                </div>
              </div>
              <!-- <input id="phone" type="text" placeholder="טלפון" :class="['form-control', {'is-invalid': errorsPhone.msg}]" v-model="form.phone">             -->
            </div>
            <!--<div class="invalid-feedback" v-if="errorsPhone.msg" v-text="errorsPhone.msg" />
            <div class="invalid-feedback" v-if="errorsAreaPhone.msg" v-text="errorsAreaPhone.msg" /> -->
          </div>

        </div>

        <div class="form-group">
             <v-select
              v-model="form.city"
              :options="cities"
              dir="rtl"
              :class="['style-searchSelect', {'is-invalid': errorsCity.msg}]"
              placeholder="עיר"
              @search="searchCities"
              >
                <template v-slot:no-options="{ search, searching }" >
                  <template v-if="searching">
                    לא נמצא עיר בשם <em>{{ search }}</em>.
                  </template>
                  <em style="opacity: 0.5;" v-else>הקלידי את עירך</em>
                </template>
              </v-select>
          <div class="invalid-feedback" v-if="errorsCity.msg" v-text="errorsCity.msg" />
        </div>

        <div class="form-group">
          <v-select
            :disabled="withoutStreet"
            v-model="form.street"
            :options="address"
            :reduce="address => address.code"
            label="title"
            dir="rtl"
            :class="['style-searchSelect', {'is-invalid': errorsStreet.msg}]"
            placeholder="רחוב"
            @search="searchAdress"
            >
              <template v-slot:no-options="{ search, searching }" >
                <template v-if="searching">
                  לא נמצא רחוב בשם <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>הקלידי את שם הרחוב</em>
              </template>
          </v-select>
          <div class="invalid-feedback" v-if="errorsStreet.msg" v-text="errorsStreet.msg" />
        </div>
        <div class="form-group">
          <label class="m-0 checkout-note align-items-center d-flex">
            <input type="checkbox" class="ml-2" v-model="withoutStreet" >
             ללא רחוב
          </label>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <input type="text" placeholder="מספר דירה" :class="['form-control', {'is-invalid': errorsDiraNum.msg}]" v-model="form.diraNum" :disabled="withoutStreet">
            <div class="invalid-feedback" v-if="errorsDiraNum.msg" v-text="errorsDiraNum.msg" />
          </div>

          <div class="form-group col">
            <input id="houseNum" type="text" placeholder="מספר בית" :class="['form-control', {'is-invalid': errorsHouseNum.msg}]" v-model="form.houseNum" :disabled="withoutStreet">
            <div class="invalid-feedback" v-if="errorsHouseNum.msg" v-text="errorsHouseNum.msg" />
          </div>
        </div>

        <!-- <div class="form-group">
          <input type="text" placeholder="עיר" :class="['form-control', {'is-invalid': errorsCity.msg}]" v-model="form.city">
          <div class="invalid-feedback" v-if="errorsCity.msg" v-text="errorsCity.msg" />
        </div> -->


        <div class="form-group">
          <textarea class="form-control" v-model="form.notes" rows="5" placeholder="הערות" />
        </div>

        <div class="form-group">
          <div class="input terms-input inline position-relative d-flex align-items-baseline">
            <input type="checkbox" id="terms" v-model="terms" class="terms-checkbox" />
            <label class="mr-2" for="terms">
              אני מאשר/ת את התקנון ומסכים/ה לקבל תוכן שיווקי בכל המדיות שמסרתי בהרשמה
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventEmitter } from '@/main'
import api from '@/api'

export default {
  name: 'ShippingDetails',

  data() {
    return {
      streetsList: [
        'tel aviv',
        'gan yavne'
      ],
      withoutStreet:false,
      deliveries: [],
      areaCodes: [],
      fromWatch: false,
      terms: true,
      form: {
        delivery: null,
        lname: '',
        fname: '',
        email: '',
        phone: '',
        street: '',
        areaPhone:'',
        houseNum: '',
        diraNum: '',
        city: '',
        notes: '',
        password: null,
      }
    }
  },

  computed: {
    details() {
      return this.$store.getters.details
    },
    errorsFname() {
      return this.$store.getters.errorsFname
    },
    errorsLname() {
      return this.$store.getters.errorsLname
    },
    errorsEmail() {
      return this.$store.getters.errorsEmail
    },
    errorsPhone() {
      return this.$store.getters.errorsPhone
    },
    errorsAreaPhone() {
      return this.$store.getters.errorsAreaPhone
    },
    errorsStreet() {
      return this.$store.getters.errorsStreet
    },
    errorsDiraNum() {
      return this.$store.getters.errorsDiraNum
    },
    errorsHouseNum() {
      return this.$store.getters.errorsHouseNum
    },
    errorsCity() {
      return this.$store.getters.errorsCity
    },
    errorsDelivery() {
      return this.$store.getters.errorsDelivery
    },
    allErrors() {
      return this.$store.getters.allErrors
    },
    user() {
      return this.$store.getters.user
    },
    isLogin() {
      return this.$store.getters.isLogin
    },
    cities() {
      return this.$store.getters.placesCities
    },
    address() {
      return this.$store.getters.placesAddresses
    }
  },

  methods: {
    async searchCities(search, loading){
      if(search){
        loading(true)
        await this.$store.dispatch('getPlacesCitiesFromGoogle', search)
        loading(false)
      }
    },
    async searchAdress(search, loading){
      if(search){
        loading(true)
        await this.$store.dispatch('getPlacesAdressFromGoogle', { search:search , city: this.form.city })
        loading(false)
      }
    }
  },

  watch: {
    withoutStreet(newval){
      if(newval){
        this.form.street = 'ללא'
        this.form.houseNum = 0
        this.form.diraNum = 0
      }else{
        this.form.street = ''
        this.form.houseNum = ''
        this.form.diraNum = ''
      }
    },
    details(newDetails) {
      this.deliveries = newDetails.data ? newDetails.data.deliveryOpt : []
      this.areaCodes = newDetails.data ? newDetails.data.areaCodes : []
    },

    allErrors(newVal) {
       newVal.forEach(err => document.querySelector('#'+err.key).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      }))
    },

    deliveries(newDeliveries) {
      if(this.details.data && this.details.data.cart && this.details.data.cart.delivery.id === 0) {
        this.form.delivery = newDeliveries.find(de => de.id === 1).id
      } else {
        this.fromWatch = true
        this.form.delivery = (this.details.data && this.details.data.cart) ? parseInt(this.details.data.cart.delivery.id) : null
      }
    },

    'form.delivery': {
      async handler(newDelivery) {
        if(!this.fromWatch) {
          const form = new FormData()
          form.append('optID', newDelivery)

          await this.$store.dispatch('updateDelivery', {form})
        }
        this.fromWatch = false
      }
    }
  },

  created() {
    eventEmitter.$on('checkCoupon', async (codeCoupon) => {
       await this.$store.dispatch('checkVouchers', {codeCoupon: codeCoupon, phone: this.form.areaPhone+''+this.form.phone})
    })
    eventEmitter.$on('cancelCoupon', async (codeCoupon) => {
       await this.$store.dispatch('cancelVouchers')
    })
    eventEmitter.$on('checkShippingDetails', async () => {
      
      const form = new FormData()
      Object.keys(this.form).forEach(key => key !== null ? form.append(key, this.form[key]) : null)

      await this.$store.dispatch('updateDetailsUser', {form})
    })

    if(this.isLogin) {
      if(this.user.phone && this.user.phone.length > 7){
        var  areaPhone = this.user.phone.substr(0, 3)
        var  phone = this.user.phone.substr(3)
      }
      this.form.lname = this.user.lname
      this.form.fname = this.user.fname
      this.form.email = this.user.mail
      this.form.phone = this.user.phone
      this.form.phone = phone
      this.form.areaPhone = areaPhone
      this.form.street = this.user.street
      this.form.houseNum = this.user.houseNum
      this.form.diraNum = this.user.diraNum
      this.form.city = this.user.address
    }
  },

  destroyed() {
    eventEmitter.$off('checkShippingDetails')
    eventEmitter.$off('checkCoupon')
    eventEmitter.$off('cancelCoupon')
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.checkout-note{
  font-size: .9rem;
  margin-bottom: 1.6rem;
}
.gong-form .form-control{
  height: 3.3rem;
  font-size: .8rem;
}
.gong-form textarea.form-control{
  height: inherit;
}
.gong-form .custom-control-input.is-invalid ~ .custom-control-label{
  color: #000;
}

@include media-breakpoint-down(sm) {
  .checkout-note {
    font-size: .7rem;
    margin-bottom: 1rem;
  }
}
</style>
