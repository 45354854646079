<template>
  <div id="checkout">
    <section class="site-section">
      <div class="container-fluid">

        <div class="section-header">
          <h2 class="section-title" v-text="'פרטי משלוח ותשלום'" />
        </div>

        <div class="section-body">
          <form action="/" method="post" class="gong-form">
            <div v-if="statusRejected" class="row justify-content-center mb-3">
              <div class="col-md-6">
                <div class="alert alert-danger" v-text="'התשלום נכשל. נסה שוב!'" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="checkout-container">
                  <PaymentMethods />

                  <div class="checkout-footer">
                    <OrderSummary />
                  </div>
                </div>
              </div>

              <div class="col-md-5 offset-md-1">
                <ShippingDetails />
              </div>

              <div class="col-md-3 text-right">
                <div class="small-cart">
                  <div class="small-cart-header">
                    <h4 class="small-cart-title" v-text="'1. סל הקניות שלך'" />
                  </div>

                  <div v-if="cart.products" class="small-cart-body custom-scroll-dark">
                    <CardProductOrder
                      v-for="(product, index) of cart.products"
                      :key="index"
                      :product="product"
                      :changeQuantity="false"
                      :canDelete="false"
                    />
                  </div>

                  <div class="small-cart-footer">
                    <div class="cart-checkout intermediate-summary">
                      <p><span class="shekel" v-text="'₪'" /> {{allPrice}}</p>
                      <p>סיכום ביניים ( {{allQuantity}} מוצרים)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import cartMixin from '@/mixins/cart.mixin'
import PaymentMethods from '@/components/checkout/PaymentMethods'
import ShippingDetails from '@/components/checkout/ShippingDetails'
import OrderSummary from '@/components/checkout/OrderSummary'
import CardProductOrder from '@/components/CardProductOrder'

export default {
  name: 'Checkout',

  components: {
    PaymentMethods,
    ShippingDetails,
    OrderSummary,
    CardProductOrder
  },

  mixins: [cartMixin],

  props: {
    isGuest: { type: Boolean, default: false }
  },

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME + ' | פרטי משלוח ותשלום | ' + process.env.VUE_APP_NAME_HEB
    }
  },

  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    },
    cart() {
      return this.$store.state.cart.cart
    },
    details() {
      return this.$store.getters.details
    },
    errorsCart() {
      return this.$store.getters.errorsCart
    },
    statusRejected () {
      return this.$route.query.status === 'rejected'
    }
  },

  watch: {
    errorsCart(newErrorsCart) {
      this.$store.commit('setErrorCartFromCheckout', newErrorsCart)
    }
  },

  async created () {
    if(!this.isLogin && !this.isGuest && !this.$route.query.isGuest) {
      this.$router.push({ name: 'login', params: { fromCheckout: true } })
    }

    await this.$store.dispatch('getDetails')

    if (this.$route.query && this.$route.query.status === 'rejected') {
      // console.log(this.$route.query)
      const resp = await api.payment.failure(this.$route.query)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#checkout .container-fluid {
  margin-left: 7.65vw;
  margin-right: 5.81vw;
  width: inherit;
}

.alert {
  text-align: right;
  direction: rtl;
}
.section-header {
  margin-bottom: 3.3rem;
}
.section-body .row > .col-md-4:first-child{
  padding-right: 1.95vw;
}
.section-body .row > .col-md-5{
  margin: 0 auto;
  padding: 0 5vw;
}
.small-cart{
  border: none;
}
.small-cart-body{
  height: 32.3rem;
  overflow-y: scroll;
  direction: rtl;
}
.small-cart-header{
  justify-content: flex-end;
  padding: 1.3rem 1.2rem 1.2rem;
  background-color: #fafafa;
}
.small-cart-title{
  color: #000;
  font-size: 1.3rem;
  direction: rtl;
}
.small-cart-footer{
  background-color: #fafafa;
  padding: 2rem 1.3rem .4rem 2.1rem;
}

@include media-breakpoint-down(sm) {
  .section-body .row > .col-md-4:first-child{
    padding-right: 15px;
  }
  .section-body .row > .col-md-3:last-child {
    display: none;
  }
  .section-body .row > .col-md-5{
    padding: 0 15px;
  }
  #checkout .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }
  .site-section{
    margin-top: 3.2rem;
  }
  .section-body .row{
    flex-direction: column-reverse;
  }
  #checkout .section-title{
    font-size: 1.2rem;
  }
  .section-header{
    margin-bottom: .8rem;
  }
  .checkout-footer{
    margin-top: 3.6rem;
  }
  .validity {
    font-size: .8rem;
    font-weight: bold;
  }
  .gong-form .form-text{
    text-align: left;
    font-size: .5rem;
  }
  #checkout .gong-form .checkout-footer .btn {
    font-size: 1.1rem;
    height: 3rem;
    line-height: 1;
    margin-top: 1.4rem;
  }
}
@include media-breakpoint-down(md) {
  #checkout .container-fluid {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-body .row > .col-md-4:first-child {
    padding-right: 15px;
  }
  .section-body .row > .col-md-5 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .small-cart-header {
    padding-right: 15px;
    padding-left: 15px;
  }
}
</style>
